import $ from 'jquery';

import ChairishHistory from 'chairisher/util/history';
import FacebookAnalytics from 'chairisher/analytics/facebook';
import ReferrerUtils from 'chairisher/util/referrer';
import UriUtils from 'chairisher/util/uri';

import initializeAmplitude from 'chairisher/thirdparty/amplitude';

import { getAmplitudeTestTags } from 'chairisher/context/abtest';
import { isDataSharingOptedOut } from 'chairisher/context/account';
import {
    getGoogleConversionId,
    getUtmCampaign,
    getUtmMedium,
    getUtmSource,
    purgeAmplitudeTestTags,
} from 'chairisher/context/tracking';
import { getUrlName, shouldDebugAmplitude } from 'chairisher/context/site';
import { getBuyerTypeCode, getGuid, isIdentified, isTrade } from 'chairisher/context/auth';

/**
 * @enum {string}
 */
export const TrackingCategory = {
    FOOTER: 'Footer',
    MODAL: 'Modal',
    PANEL: 'Panel',
};

/**
 * @enum {string}
 */
export const TrackingAction = {
    COLLECTION_SUCCESS: 'SuccessfulCollection',
    DOWNLOAD: 'download',
    PRINT: 'print',
    THUMBNAIL_CLICK: 'thumbnail - click',
};

/**
 * @enum {string}
 */
export const TrackingLabel = {
    EMAIL_FOOTER: 'Email Footer', // marketing email in the footer
    EMAIL_MODAL: 'Email Modal', // marketing email modal
    EMAIL_MODAL_GATED: 'Gated Email Modal', // gated marketing email modal
    EMAIL_PANEL: 'Email Panel', // inline marketing email panel,
    PDP_IMAGE_VIEWER: 'PDP ImageViewer', // image viewer on PDP
};

/**
 * Specifies where an express checkout was started.
 *
 * @enum {string}
 */
export const TrackingExpressCheckoutOrigin = {
    CART: 'cart',
    MINICART: 'minicart',
};

/**
 * @enum {string}
 */
export const TrackingScreen = {
    BUSINESS_REGISTER: 'business register',
    COLLECTION: 'collection',
    PDP: 'pdp',
    SEARCH_RESULTS: 'search results',
    SHOP: 'shop',
    TRADE_CONFIRMED: 'trade - confirmed',
    TRADE_INFORMATION: 'trade - information',
    TRADE_REGISTER: 'trade - register',
};

/**
 * @enum {string}
 */
export const BannerPosition = {
    GLOBAL_PROMO_BANNER: 'global promo banner',
};

/**
 * @enum {string}
 */
export const BusinessSearchPosition = {
    CITY_COLLECTION_RAIL: 'design pro city collection rail',
    DESIGNER_CONTACT: 'contact designer',
    DESIGNER_DIRECTORY: 'designer directory',
    POPULAR_LOCATION_RAIL: 'design pro popular location rail',
};

/**
 * @enum {string}
 */
export const CollectionPosition = {
    BOTTOM_BANNER: 'bottom banner',
    EXTENDED_NAV_SUBCATEGORIES: 'extended nav subcategories',
    FAQ: 'faq',
    MAIN_PRODUCT_GRID: 'main product grid',
    SIMPLE_QUERY_SEARCH: 'simple query search',
    VISUAL_NAV: 'collection visual nav',
};

/**
 * @enum {string}
 */
export const GooglePlacesPosition = {
    UNVERIFIED_ADDRESS: 'google places unverified address',
};

/**
 * @enum {string}
 */
export const ModalPosition = {
    AUTH: 'authentication modal (gated)',
};

/**
 * Tracking positions for Chairish navigation
 *
 * @enum {string}
 */
export const NavigationPosition = {
    MOTD: 'motd banner',
    PRODUCT_NAV: 'product nav',
    PRODUCT_NAV_HORIZONTAL: 'product nav horizontal',
    DESIGNER_DIRECTORY_CTA: 'designer directory cta',
    FAVORITES_DROP_DOWN_MENU: 'favorites drop down menu',
    HEADER_CART_ICON: 'header cart icon',
};

/**
 * @enum {string}
 */
export const CheckoutPosition = {
    ADD_TO_CART: 'add to cart',
    CART: 'cart',
    HOW_OFFERS_WORK: 'how offers work',
    MAO_MODAL: 'mao modal',
    MINI_CART: 'minicart',
    OFFER_CREATE: 'offer create',
};

/**
 * @enum {string}
 */
export const CheckoutProtectionPlanAction = {
    ADD: 'add',
    DECLINE: 'decline',
    NO: 'no',
    REMOVE: 'remove',
};

/**
 * @enum {string}
 */
export const PDPSection = {
    CAROUSEL: 'carousel',
    COMMENTS: 'comments',
    DETAILS: 'details',
    EXTENDED_RETURN_POLICIES: 'extended return policies',
    PDP: 'product detail',
    PLA_LINK: 'pla link',
    RETURNS: 'returns',
    REVIEWS_LINK: 'reviews link',
    SAMPLE_MODAL: 'sample modal',
    SHIPPING_OPTIONS: 'shipping options',
    SOCIAL_BAR: 'pdp social bar',
    THUMBNAILS: 'thumbnails',
    VARIANT_OPTIONS: 'variant options',
    VIEW_MORE_NEAR_YOU: 'view more near you',
};

/**
 * @enum {string}
 */
export const ProductRailPosition = {
    BOTTOM: 'bottom',
    TOP: 'top',
};

/**
 * @enum {string}
 */
export const BuyerSellerCommunicationPosition = {
    DETAIL: 'bsc channel detail',
    LIST: 'bsc channel list',
    MODAL: 'bsc channel detail modal',
};

/**
 * @enum {string}
 */
export const SearchPosition = {
    BOTTOM_PAGINATION: 'bottom pagination',
    BOTTOM_SAVE_SEARCH: 'saved search bottom',
    RECENTLY_VIEWED: 'search recently viewed',
    TOP_PAGINATION: 'top pagination',
};

/**
 * @enum {string}
 */
export const SellerPlanPosition = {
    MODIFY_SELLER_PLAN_MODAL: 'modify seller plan modal',
    SELLER_PLAN_BANNER_ADS: 'elite & plus banner ads',
    SELLER_PLAN_BENEFITS: 'seller plan benefits',
    SELLER_TODO_LIST: 'seller to-do list',
};

/**
 * @enum {string}
 */
export const ShippingLabelPosition = {
    REMOVE_UNUSED_LABELS: 'remove unused labels',
};

/**
 * @enum {string}
 */
export const ShopPosition = {
    CONTACT_BUTTON: 'contact button',
    CONTACT_ANCHOR_TAG: 'contact anchor tag',
    LOCATION_LINK: 'location link',
    NEWS_RAIL: 'news rail',
    REVIEWS_LINK: 'reviews link',
};

/**
 * @enum {string}
 */
export const TradePosition = {
    REGISTER: 'trade register',
    SIGNUP_TOP: 'trade signup top',
    SIGNUP_BOTTOM: 'trade signup bottom',
};

/**
 * @enum {string}
 */
export const UhpPosition = {
    DESIGNER_DIRECTORY: 'uhp design directory cta',
    FEATURED_COLLECTIONS: 'featured collections',
    FEATURED_NEW_ARRIVALS_BOTTOM: 'featured new arrivals bottom',
    FEATURED_NEW_ARRIVALS_TOP: 'featured new arrivals top',
    HERO: 'hero',
    NEW_ARRIVALS: 'new arrivals',
    PORTHOLES: 'portholes',
    RECENTLY_VIEWED: 'uhp recently viewed',
    SHOP_BY_CATEGORY: 'shop by category',
    STAFF_PICKS: 'staff picks',
    SUSTAINABILITY_MODULE: 'sustainability module',
    TRADE_MODULE: 'trade module home screen',
    WHOLESALE_RAIL: 'uhp best of new and custom',
};

/**
 * @enum {string}
 */
export const FolderCardPosition = {
    PRODUCT_GRID: 'product grid',
    SAMPLE_MODAL: 'sample modal',
};

/**
 * @enum {string}
 */
export const GlobalPosition = {
    BREADCRUMBS: 'breadcrumbs',
    HEADER: 'header',
};

/**
 * Enumeration of product rail subtypes for use in analytic properties.
 * @enum {string}
 */
export const ProductRailSubType = {
    ORGANIC: 'organic',
    POPUP: 'popup',
};

/**
 * Enumeration of product rail types for use in analytic properties.
 * @enum {string}
 */
export const ProductRailType = {
    COMPLETE_THE_LOOK: 'complete the look',
    MORE_LIKE_THIS: 'more like this',
    NEW_ARRIVALS: 'new arrivals',
    PRODUCT_LINE: 'product line',
    PROMOTED_LISTINGS: 'promoted listings',
    RECENTLY_VIEWED: 'recently viewed',
    TASTEMAKER: 'tastemaker',
    WHOLESALE: 'wholesale',
    YOU_MAY_ALSO_LIKE: 'you may also like',
};

/**
 * Enum of event property keys used in Amplitude to ensure internal consistency
 * @enum {string}
 */
export const AmplitudeEventProperty = {
    ARE_MESSAGES_ENABLED: 'are messages enabled',
    BADGE: 'badge',
    BUSINESS_ID: 'business id',
    BUSINESS_TYPE: 'business type',
    CANONICAL_TERM: 'canonical term',
    CATEGORY_CODE: 'category code',
    CHANNEL_USER_TYPE: 'channel user type',
    CODE: 'code',
    COLLECTION_HANDLE: 'collection handle',
    COLLECTION_ID: 'collection id',
    CONTAINS_VARIANT: 'contains variant',
    CTA_URL: 'cta url',
    CTA_NAME: 'cta name',
    CTA_POSITION: 'cta position', // deprecated; please use POSITION instead...
    CUBIC_FEET: 'cubic feet',
    DISPLAY: 'display',
    DISCOUNT_PERCENT: 'discount percent',
    DEALER_GUID: 'dealer guid',
    EMAIL_TEMPLATE: 'email template',
    ESTIMATED_ARRIVAL_TIMEFRAME: 'estimated arrival timeframe',
    EXPRESS_CHECKOUT_ORIGIN: 'express checkout origin',
    FIELD_NAME: 'field name',
    GUID: 'guid',
    HAS_PROP_65_WARNING: 'has prop 65 warning',
    HAS_VISUAL_NAV: 'has visual nav',
    INITIATOR: 'initiator',
    IS_BOOSTED: 'is boosted',
    IS_BULK_APPLY: 'is bulk apply',
    IS_CURATED: 'is curated',
    IS_DMA_MATCH: 'is dma match',
    IS_ELIGIBLE_PROTECTION_PLAN: 'is eligible for protection plan',
    IS_FOLDER: 'is folder',
    IS_GATED: 'is gated',
    IS_HOUSE_AD: 'is house ad',
    IS_MARKDOWN: 'is markdown',
    IS_NEWLY_MADE: 'is newly made',
    IS_ON_HOLD: 'is on hold',
    IS_ON_PRIVATE_SALE_FOR_USER: 'is on private sale for user',
    IS_ON_PUBLIC_SALE: 'is on sale',
    IS_OPT_OUT: 'is opt out',
    IS_PAMONO: 'is pamono',
    IS_PART_OF_PRIVATE_SALE: 'is part of private sale',
    IS_PREVIOUS_INVALID_OFFER: 'is previous invalid offer',
    IS_PRICE_HIDDEN: 'is price hidden',
    IS_PRIVATE_OFFER: 'is private offer',
    IS_PROMOTED_LISTING: 'is promoted listing',
    // IS_REDIRECT: 'is redirect' (replaced by REDIRECT_TYPE in March 2024)
    IS_SAMPLE_AVAILABLE: 'is sample available',
    IS_SELLER_INITIATED_OFFER: 'is seller initiated offer',
    IS_TRADE_USER: 'is trade user',
    IS_VARIANT: 'is variant',
    IS_VIEWER_EMAIL_CONFIRMED: 'is viewer email confirmed',
    IS_WHOLESALE: 'is wholesale',
    JS_ERROR: 'js error',
    LEAD_TIME: 'lead time',
    LOCATION: 'location',
    NUM_RESULTS: 'number of results',
    NUM_TOKENS: 'num tokens',
    OFFER_ID: 'offer id',
    OFFER_PRICE: 'offer price',
    ORDER_ID: 'order_id',
    PAYMENT_METHOD: 'payment method',
    PERMISSIONS_GROUPS: 'permissions groups',
    POSITION: 'position',
    PRICE: 'price',
    PROCESSING_FEE_AMOUNT: 'processing fee amount',
    PRODUCT_GROUP_ID: 'product group id',
    PRODUCT_ID: 'product id',
    PRODUCT_IDS: 'product ids',
    PRODUCT_INDEX: 'product index',
    PRODUCT_PRICE: 'product price',
    PRODUCT_SELLER_TYPE: 'product seller type',
    PRODUCT_STATE_DISPLAY: 'product state display',
    PRODUCT_TAXONOMY: 'product taxonomy',
    PROTECTION_PLAN_DETAILS: 'protection plan details',
    QUANTITY: 'quantity',
    QUOSS_ASSIGNMENT: 'quoss assignment',
    QUERY: 'query',
    QUERY_TEXT: 'query text',
    REDIRECT_TYPE: 'redirect type',
    REFERRING_COLLECTION: 'referring collection',
    SCREEN: 'screen',
    SCREEN_URL: 'screen url',
    SELLER_RATING: 'seller rating',
    SERVICE_FEE_AMOUNT: 'service fee amount',
    SERVICE_FEE_RATE: 'service fee rate',
    SHIPPING_COST: 'shipping cost',
    SHIPPING_DISTANCE: 'shipping distance',
    SHIPPING_ENGINE_COST: 'shipping engine cost',
    SHIPPING_PARTNER_CODE: 'shipping partner code',
    SHIPPING_TYPE_CODE: 'shipping type code',
    SHOP_URL: 'shop url',
    SPOTLIGHT_TYPE_CODES: 'spotlight type codes',
    SUB_TYPE: 'sub type',
    SUGGESTION_NAME: 'suggestion name',
    SUGGESTION_URL: 'suggestion url',
    TAXONOMY: 'taxonomy',
    TO_GUID: 'to guid',
    TRADE_DISCOUNT_PERCENT: 'trade discount percent',
    TYPE: 'type',
    UNIT_TYPE: 'unit type',
    URL_NAME: 'screen url name',
    USER_TYPE: 'user type',
    VALUE: 'value',
    VARIABLE_ATTRIBUTE_NAME: 'variable attribute name',
    VARIABLE_ATTRIBUTE_VALUE: 'variable attribute value',
    VERIFY_EMAIL: 'verify email',
    WAS_SELECTED: 'was selected',
    ZE_ACTION: 'zendesk action',
    ZE_CATEGORY: 'zendesk category',
};

/**
 * Enum of social media platform names used for tracking purposes.
 * @type {string}
 */
export const TrackingSocialMediaPlatform = {
    DEVICE_SHARE: 'Device Share',
    EMAIL: 'Email',
    FACEBOOK: 'Facebook',
    INSTAGRAM: 'Instagram',
    LINK_COPY: 'Link Copy',
    PINTEREST: 'Pinterest',
    TWITTER: 'Twitter',
};

const abTestTagsProperty = 'ab_test_tags';

class AmplitudeState {
    constructor() {
        this.isAmplitudeUserIdSet = false;
        this.amplitude = null;
    }

    initialize() {
        this.amplitude = initializeAmplitude();
    }

    /**
     * Alternative to amplitude event explorer browser extension
     */
    debug(eventName, eventObject) {
        console.info('[amplitude debug] %c"%s" %o', 'font-weight: 600;', eventName, eventObject);
    }

    /**
     * Sends an event to Amplitude
     *
     * @param {string} eventName
     * @param {Object=} originalEventObject
     */
    logEvent(eventName, originalEventObject) {
        const shouldDebug = shouldDebugAmplitude();
        let eventObject = {
            ...originalEventObject,
            [AmplitudeEventProperty.IS_TRADE_USER]: isTrade(),
            [AmplitudeEventProperty.SCREEN_URL]: document.location.pathname,
            [AmplitudeEventProperty.GUID]: getGuid(),
        };

        if (this.amplitude) {
            const amplitudeInstance = this.amplitude.getInstance();

            if (!this.isAmplitudeUserIdSet && isIdentified()) {
                amplitudeInstance.setUserId(getGuid());
                this.isAmplitudeUserIdSet = true;
            }

            const identify = new amplitudeInstance.Identify();
            let shouldIdentify = false;

            let attributionSource;
            let attributionMedium;
            let attributionCampaign;

            const referrer = ReferrerUtils.getReferrer();
            const referreringDomain = ReferrerUtils.getReferringDomain(referrer);
            const utmSource = getUtmSource();

            if (utmSource) {
                attributionSource = utmSource;

                if (UriUtils.uriOriginatesFromPinterestOrganic()) {
                    attributionCampaign = 'Pinterest_Feed';
                    attributionMedium = 'social';
                } else if (UriUtils.uriOriginatesFromGoogleShopping()) {
                    attributionCampaign = 'Google_Feed';
                    attributionMedium = 'referral';
                } else {
                    attributionCampaign = getUtmCampaign();
                    attributionMedium = getUtmMedium();
                }
            } else if (referrer && referreringDomain.indexOf(UriUtils.getHostname(true)) === -1) {
                attributionCampaign = referrer;
                attributionMedium = 'referral';
                attributionSource = referreringDomain;
            }

            if (attributionSource && attributionMedium && attributionCampaign && amplitudeInstance.isNewSession()) {
                shouldIdentify = true;

                eventObject = $.extend(eventObject, {
                    attributionCampaign,
                    attributionMedium,
                    attributionSource,
                });

                // Read private attributes from amplitudes JS object (may break when we upgrade version)
                const deviceObject = amplitudeInstance._ua.device; // eslint-disable-line no-underscore-dangle
                const osObject = amplitudeInstance._ua.os; // eslint-disable-line no-underscore-dangle

                const attributionComponents = [
                    new Date().getTime() / 1000, // Seconds to match Python/Cocoa's timestamp
                    attributionSource.replace(/\|/g, '_'),
                    attributionMedium.replace(/\|/g, '_'),
                    attributionCampaign.replace(/\|/g, '_'),

                    // Note: the following attributes are taken from the Amplitude library's
                    //       private internals; if the data isn't available DO NOT provide any
                    //       fallback per James' request despite Amplitude backfilling data in
                    //       on their webapp.
                    amplitudeInstance.options.platform,
                    deviceObject.type || 'NONE',
                    deviceObject.model || 'NONE',
                    osObject.name || 'NONE',
                ];

                identify
                    .prepend('attributionCampaign', attributionCampaign)
                    .prepend('attributionMedium', attributionMedium)
                    .prepend('attributionSource', attributionSource)
                    .prepend('attribution', attributionComponents.join('|'));

                const buyerTypeCode = getBuyerTypeCode();
                if (buyerTypeCode) {
                    identify.set('buyer_type_code', buyerTypeCode);
                }
            }

            const abTestTags = getAmplitudeTestTags();
            if (abTestTags.length) {
                purgeAmplitudeTestTags();
                shouldIdentify = true;
                identify.prepend(abTestTagsProperty, abTestTags); // Will be flattened
            }

            if (shouldIdentify) {
                if (isTrade()) {
                    identify.set('is_trade', true);
                }

                if (shouldDebug) {
                    this.debug('identify', eventObject);
                }

                amplitudeInstance.identify(identify);
            }

            amplitudeInstance.logEvent(eventName, eventObject);
        }

        // wait one second to clean up UTM tags from the URL to ensure that
        // Amplitude has had time to send them up when identify() is called...
        const cleanseId = window.setInterval(() => {
            ChairishHistory.cleanseUtmTagsFromUri();
            window.clearInterval(cleanseId);
        }, 1000);

        if (shouldDebug) {
            this.debug(eventName, eventObject);
        }
    }

    setAmplitudeTestTag(abTestTag) {
        if (this.amplitude) {
            const amplitudeInstance = this.amplitude.getInstance();
            const identify = new amplitudeInstance.Identify();
            identify.prepend(abTestTagsProperty, [abTestTag]);
            amplitudeInstance.identify(identify);
        }
        if (shouldDebugAmplitude()) {
            this.debug('setAmplitudeTestTag', abTestTag);
        }
    }
}

let amplitudeStateInstance = null;

/**
 * Initializes analytic state.
 * This should be called only once immediately after the account data callback to ensure
 * that the page is ready & relevant context values are set.
 */
export function initializeAnalytics() {
    if (!amplitudeStateInstance) {
        amplitudeStateInstance = new AmplitudeState();
        amplitudeStateInstance.initialize();
    }
}

/**
 * Manually sets an abtest tag. Useful when bucketing needs to happen in the middle of page lifecycle.
 *
 * @param {Array<string>} abTestTag
 */
export function setAmplitudeTestTag(abTestTag) {
    if (amplitudeStateInstance) {
        amplitudeStateInstance.setAmplitudeTestTag(abTestTag);
    }
}

/**
 * Sends an event in to Amplitude
 *
 * @param {string} eventName
 * @param {Object=} eventObject
 */
export function logAmplitudeEvent(eventName, eventObject = {}) {
    if (!amplitudeStateInstance) {
        // This can happen if an analytic call is made before the account data callback.
        // This is never expected. But, if we make a mistake, print something to console instead of crashing page.
        console.error(`analytics not ready '${eventName}':`, eventObject);
    } else {
        amplitudeStateInstance.logEvent(eventName, eventObject);
    }
}

/**
 * Tracks when a user clicks on an element related to a specific CTA
 *
 * @param {string} ctaName The CTA name of the element
 * @param {string} ctaPosition The position of the CTA on the page
 * @param {string|null=} ctaUrl Optional destination url of the CTA
 * @param {Object=} eventProperties Optional event properties to send to Amplitude
 */
export function logAmplitudeClickEvent(ctaName, ctaPosition, ctaUrl = null, eventProperties = {}) {
    logAmplitudeEvent('click', {
        [AmplitudeEventProperty.CTA_NAME]: ctaName,
        [AmplitudeEventProperty.CTA_POSITION]: ctaPosition,
        [AmplitudeEventProperty.CTA_URL]: ctaUrl,
        ...eventProperties,
    });
}

/**
 * Logs an error. In the future, it would be better to send this to a specialized error aggregation service.
 *
 * @param {string} eventName
 * @param {Error} error
 */
export function logError(eventName, error) {
    console.error(error);
    logAmplitudeEvent(eventName, {
        [AmplitudeEventProperty.JS_ERROR]: error.toString(),
        [AmplitudeEventProperty.URL_NAME]: getUrlName(),
    });
}

/**
 * Tracks when a user clicks on a link related to a specific CTA
 *
 * @deprecated Use logAmplitudeClickEvent instead
 *
 * @param {string} ctaName The CTA name of the link
 * @param {string} ctaUrl The destination url of the CTA
 * @param {string} ctaPosition The position of the CTA on the page
 * @param {Object=} eventProperties Optional event properties to send to Amplitude
 */
export function logAmplitudeLinkClickEvent(ctaName, ctaUrl, ctaPosition, eventProperties = {}) {
    logAmplitudeEvent('link - click', {
        [AmplitudeEventProperty.CTA_URL]: ctaUrl,
        [AmplitudeEventProperty.CTA_NAME]: ctaName,
        [AmplitudeEventProperty.CTA_POSITION]: ctaPosition,
        ...eventProperties,
    });
}

/**
 * Track a view of a screen
 *
 * @param {TrackingScreen|string} screen
 * @param {Object=} eventProperties
 */
export function logScreenView(screen, eventProperties) {
    logAmplitudeEvent(`screen - ${screen}`, eventProperties);
}

/**
 * Track when a user clicks on a button
 *
 * @deprecated Use logAmplitudeClickEvent instead
 *
 * @param {string} ctaName The CTA name on the button
 * @param {Object=} eventProperties
 */
export function logAmplitudeButtonClickEvent(ctaName, eventProperties = {}) {
    logAmplitudeEvent('button - click', {
        [AmplitudeEventProperty.CTA_NAME]: ctaName,
        ...eventProperties,
    });
}

/**
 * A replacement for the document's write method which uses DOM manipulation after the document
 * has loaded.
 *
 * @param {string} text The text to write to the page buffer
 * @return {jQuery|undefined} A jQuery object representing the new content's container, or
 *     undefined if the document is still loading.
 */
function swizzledDocWrite(text) {
    return $(window.document.body).children().first().append(text);
}

/**
 * Tracks an AdWords conversion
 *
 * @param {string} label The label associated with this conversion event
 * @param {string|number} value The monetary value to be associated with this conversion event
 */
export function trackAdwordsConversion(label, value) {
    if (!isDataSharingOptedOut()) {
        window.google_conversion_id = getGoogleConversionId();
        window.google_conversion_language = 'en';
        window.google_conversion_format = '3';
        window.google_conversion_color = 'ffffff';
        window.google_conversion_label = label || 'leL6CMG0kAQQn83I3AM';
        window.google_conversion_value = value || 0;
        window.google_conversion_currency = 'USD';
        window.google_remarketing_only = false;

        if (window.document.write !== swizzledDocWrite) {
            window.document.write = swizzledDocWrite;
        }
        $.getScript('//www.googleadservices.com/pagead/conversion.js');
    }
}

/**
 * Tracks a remarketing event (currently Criteo & Facebook)
 *
 * @param {string} eventType The eventType can be one of:
 *      viewHome: Sent when viewing the home page.
 *      viewItem: Sent when viewing a product detail page.
 *      viewList: Sent when viewing a search result, product grid, or seller shop.
 *      viewBasket: Sent when viewing a shopping cart of favoriting a product.
 *      trackTransaction: Sent when completing an offer, buy-me-now order, contacting dealer, or on email capture.
 * @param {*=} productContents The product contents of the event sent to our remarketing partner.
 *      [{
 *          category: <product.get_google_category>,  // only used by facebook viewItem
 *          id: <product.id>,
 *          price: <product.price>,
 *          quantity: <product.lot_size>,
 *          revenue: some number,
 *          title: <product.title>
 *       }, ...]
 * @param {string=} transactionId Transaction ID for the trackTransaction event
 *      Offer: 'Offer-<offer.id>'
 *      Order: 'Order-<order.id>'
 *      'ContactDealer-<random number>'
 *      ...
 * @param {Boolean=} isFavoriting true when someone is favoriting a product
 */
export function trackRemarketingEvent(eventType, productContents, transactionId, isFavoriting) {
    if (isDataSharingOptedOut()) {
        return;
    }

    let deviceType;
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)) {
        deviceType = 'm';
    } else if (/iPad/.test(navigator.userAgent)) {
        deviceType = 'd';
    }

    window.criteo_q = window.criteo_q || [];

    let remarketingEventType = eventType;
    let basketType;
    if (remarketingEventType.indexOf('viewBasket') > -1) {
        if (remarketingEventType.indexOf('Offer') > -1) {
            basketType = 'makeanoffer';
        } else if (remarketingEventType.indexOf('Order') > -1) {
            basketType = 'buymenow';
        }
        remarketingEventType = 'viewBasket';
    }

    const eventObject = { event: remarketingEventType };

    if (eventType === 'trackTransaction' && transactionId) {
        eventObject.id = transactionId;
    }

    if (productContents) {
        let maxProductsCount = productContents.length;
        if (maxProductsCount > 3) {
            maxProductsCount = 3;
        }

        switch (remarketingEventType) {
            case 'viewItem':
                eventObject.product = productContents[0].id.toString();
                break;
            case 'viewList': {
                const productIDs = [];
                for (let i = 0; i < maxProductsCount; i += 1) {
                    // Criteo can only handle up to 3
                    productIDs.push(productContents[i].id.toString());
                }
                eventObject.product = productIDs;
                break;
            }
            case 'viewBasket':
                if (basketType) {
                    eventObject.basket_type = basketType;
                }
            // purposely fall through
            case 'trackTransaction':
                eventObject.product = [];
                for (let a = 0; a < maxProductsCount; a += 1) {
                    const productContent = productContents[a];
                    eventObject.product.push({
                        id: productContent.id.toString(),
                        price: productContent.price,
                        quantity: productContent.quantity,
                    });
                }
                break;
            default:
                break;
        }
    }

    if (window.chairisher.context.IS_BLOG) {
        window.criteo_q.push(
            {
                event: 'setAccount',
                account: window.chairisher.context.CRITEO_ACCOUNT_ID,
            },
            {
                event: 'setSiteType',
                type: deviceType,
            },
            {
                event: 'setHashedEmail',
                email: [window.chairisher.context.USER_HASHED_EMAIL],
            },
            {
                event: 'setData',
                ui_campaign: 'blog',
            },
            eventObject,
        );
    } else {
        window.criteo_q.push(
            {
                event: 'setAccount',
                account: window.chairisher.context.CRITEO_ACCOUNT_ID,
            },
            {
                event: 'setSiteType',
                type: deviceType,
            },
            {
                event: 'setHashedEmail',
                email: [window.chairisher.context.USER_HASHED_EMAIL],
            },
            eventObject,
        );
    }

    if (isFavoriting) {
        window.criteo_q.push({
            event: 'setData',
            ui_segment: 'favorites',
        });
    }

    const productIds = [];
    let totalValue = 0;
    if ($.isArray(productContents)) {
        for (let j = 0; j < productContents.length; j += 1) {
            const product = productContents[j];
            productIds.push(product.id);
            totalValue += product.revenue ? product.revenue : product.price;
        }
    }

    // Facebook event types
    switch (remarketingEventType) {
        case 'viewItem': {
            const contentObj = {
                content_name: decodeURIComponent(productContents[0].title),
                content_category: decodeURIComponent(productContents[0].category),
                content_ids: [productContents[0].id],
                content_type: 'product',
            };
            if (productContents[0].price !== '') {
                contentObj.value = productContents[0].price;
                contentObj.currency = 'USD';
            }

            FacebookAnalytics.logFacebookEvent(['track', 'ViewContent', contentObj]);
            break;
        }
        case 'viewList':
            FacebookAnalytics.logFacebookEvent([
                'track',
                'Search',
                {
                    content_type: 'product',
                    content_ids: productIds,
                },
            ]);
            break;
        case 'viewBasket':
            if (isFavoriting) {
                FacebookAnalytics.logFacebookEvent([
                    'track',
                    'AddToWishlist',
                    {
                        content_name: 'Favorite Product',
                        content_ids: productIds,
                        content_type: 'product',
                        value: totalValue,
                        currency: 'USD',
                    },
                ]);
            } else {
                let contentName = '';
                if (eventType === 'viewBasketOffer') {
                    contentName = 'Initiate Offer';
                } else if (eventType === 'viewBasketOrder') {
                    contentName = 'View Cart';
                }

                FacebookAnalytics.logFacebookEvent([
                    'track',
                    'AddToCart',
                    {
                        content_name: contentName,
                        content_ids: productIds,
                        content_type: 'product',
                        value: totalValue,
                        currency: 'USD',
                    },
                ]);
            }
            break;
        default:
            break;
    }
}
