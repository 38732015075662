import CookieUtils from 'chairisher/util/cookie';

/**
 * Pulled from https://pilotdigital.com/blog/updating-the-utmz-cookie-replicator-for-2024/, but reformatted to fit
 * Chairish coding convention, and to use Chairish functions.
 */

function getDomain(url) {
    let domain = '';
    if (url) {
        const a = document.createElement('a');
        a.href = url;

        const matches = a.hostname.match(/[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/);
        if (matches !== null) {
            [domain] = matches;
        }
    }

    return domain;
}

function parseGaReferrer(referrer) {
    const values = {};
    const thisDomain = getDomain(document.location.hostname);
    let referringDomain = getDomain(referrer);

    if (referringDomain && referringDomain !== thisDomain) {
        const searchEngines = {
            'daum.net': {
                p: 'q',
                source: 'daum',
            },
            'eniro.se': {
                p: 'search_word',
                source: 'eniro',
            },
            'naver.com': {
                p: 'query',
                source: 'naver',
            },
            'yahoo.com': {
                p: 'p',
                source: 'yahoo',
            },
            'msn.com': {
                p: 'q',
                source: 'msn',
            },
            'bing.com': {
                p: 'q',
                source: 'bing',
            },
            'aol.com': {
                p: 'q',
                source: 'aol',
            },
            'lycos.com': {
                p: 'q',
                source: 'lycos',
            },
            'ask.com': {
                p: 'q',
                source: 'ask',
            },
            'altavista.com': {
                p: 'q',
                source: 'altavista',
            },
            'search.netscape.com': {
                p: 'query',
                source: 'netscape',
            },
            'cnn.com': {
                p: 'query',
                source: 'cnn',
            },
            'about.com': {
                p: 'terms',
                source: 'about',
            },
            'alltheweb.com': {
                p: 'q',
                source: 'alltheweb',
            },
            'voila.fr': {
                p: 'rdata',
                source: 'voila',
            },
            'search.virgilio.it': {
                p: 'qs',
                source: 'virgilio',
            },
            'baidu.com': {
                p: 'wd',
                source: 'baidu',
            },
            'alice.com': {
                p: 'qs',
                source: 'alice',
            },
            'yandex.com': {
                p: 'text',
                source: 'yandex',
            },
            'najdi.org.mk': {
                p: 'q',
                source: 'najdi',
            },
            'seznam.cz': {
                p: 'q',
                source: 'seznam',
            },
            'search.com': {
                p: 'q',
                source: 'search',
            },
            'wp.pl': {
                p: 'szukaj',
                source: 'wirtulana polska',
            },
            'online.onetcenter.org': {
                p: 'qt',
                source: 'o*net',
            },
            'szukacz.pl': {
                p: 'q',
                source: 'szukacz',
            },
            'yam.com': {
                p: 'k',
                source: 'yam',
            },
            'pchome.com': {
                p: 'q',
                source: 'pchome',
            },
            'kvasir.no': {
                p: 'q',
                source: 'kvasir',
            },
            'sesam.no': {
                p: 'q',
                source: 'sesam',
            },
            'ozu.es': {
                p: 'q',
                source: 'ozu',
            },
            'terra.com': {
                p: 'query',
                source: 'terra',
            },
            'mynet.com': {
                p: 'q',
                source: 'mynet',
            },
            'ekolay.net': {
                p: 'q',
                source: 'ekolay',
            },
            'rambler.ru': {
                p: 'words',
                source: 'rambler',
            },
            'google': {
                p: 'q',
                source: 'google',
            },
            'duckduckgo.com': {
                p: 'q',
                source: 'duckduckgo',
            },
            // Social media platforms for organic social
            'facebook.com': {
                p: 'q',
                source: 'facebook',
                medium: 'organic_social',
            },
            'twitter.com': {
                p: 'q',
                source: 'twitter',
                medium: 'organic_social',
            },
            'tiktok.com': {
                p: 'q',
                source: 'tiktok',
                medium: 'organic_social',
            },
            'instagram.com': {
                p: 'q',
                source: 'instagram',
                medium: 'organic_social',
            },
        };
        const a = document.createElement('a');

        a.href = referrer;

        // Shim for the billion google search engines
        if (a.hostname.indexOf('google') > -1) {
            referringDomain = 'google';
        }

        if (searchEngines[referringDomain]) {
            const searchEngine = searchEngines[referringDomain];
            const termRegex = new RegExp(`${searchEngine.p}=.*?([^&#]*|$)`, 'gi');
            const termMatches = a.search.match(termRegex);

            values.source = searchEngine.source;
            values.medium = searchEngine.medium || 'organic';
            values.campaign = '(organic)';
            values.term = (termMatches ? termMatches[0].split('=')[1] : '') || '(not provided)';
        } else {
            values.source = referringDomain;
            values.campaign = '(referral)';
            values.medium = 'referral';
            values.content = '/';
        }
    }

    return values;
}

function parseGoogleParams(urlQueryStr) {
    const campaignParams = ['source', 'medium', 'campaign', 'term', 'content', 'gclid', 'fbclid'];
    const regex = new RegExp(`(utm_(${campaignParams.join('|')})|(g|fb)clid)=.*?([^&#]*|$)`, 'gi');
    const gaParams = urlQueryStr.match(regex);
    const paramsObj = {};

    if (gaParams) {
        gaParams.forEach((paramStr) => {
            const [paramKey, paramValue] = paramStr.split('=');

            if (paramKey && paramValue) {
                paramsObj[paramKey] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
            }
        });
    }

    return paramsObj;
}

/**
 * Write __utmz cookie
 *
 * @param {Object} document
 */
function writeUtmzCookie(document) {
    const defaultCookieValueDict = {
        utmcsr: '(not set)',
        utmcmd: '(not set)',
        utmccn: '(not set)',
    };
    let cookieValueDict = {};
    const cookieName = '__utmz_chairish_02_11';
    const cookieValueStr = CookieUtils.getValueFromCookie(cookieName); // || CookieUtils.getValueFromCookie('__utmz');
    const gaParams = parseGoogleParams(`${document.location.search.replace('?', '')}${document.location.hash}`);
    const gaReferrer = parseGaReferrer(document.referrer);
    const lookbackSeconds = 7776000; // 90 days
    const nowTs = Math.floor(Date.now() / 1000);
    const paramKeyToCookieKeyDict = {
        utm_source: 'utmcsr',
        utm_medium: 'utmcmd',
        utm_campaign: 'utmccn',
        utm_content: 'utmcct',
        utm_term: 'utmctr',
        gclid: 'utmgclid',
        fbclid: 'utmfbclid',
    };
    let storedTs;

    if (cookieValueStr) {
        [, storedTs] = cookieValueStr.match(/^(\d+)\..+$/);
        if (storedTs) {
            storedTs = Number(storedTs);
        }
    }

    // override stored attributions when the previous attribution is older than our lookback window
    if (!storedTs || nowTs >= storedTs + lookbackSeconds) {
        cookieValueDict = {
            utmcsr: '(direct)',
            utmcmd: '(none)',
            utmccn: '(direct)',
        };
    }

    if (gaParams.utm_source || gaParams.gclid || gaParams.fbclid) {
        cookieValueDict = defaultCookieValueDict;
        Object.entries(gaParams).forEach(([paramKey, paramValue]) => {
            if (paramValue !== undefined) {
                cookieValueDict[paramKeyToCookieKeyDict[paramKey]] = paramValue;
            }
        });

        // Set medium based on gclid or fbclid
        if (!gaParams.utm_medium) {
            if (gaParams.gclid) {
                cookieValueDict.utmcmd = 'paid_search';
            } else if (gaParams.fbclid) {
                cookieValueDict.utmcmd = 'paid_social';
            }
        }
    } else if (!storedTs && gaReferrer.source && gaReferrer.medium) {
        cookieValueDict = defaultCookieValueDict;
        cookieValueDict.utmcsr = gaReferrer.source;
        cookieValueDict.utmcmd = gaReferrer.medium;
        if (gaReferrer.campaign) cookieValueDict.utmccn = gaReferrer.campaign;
        if (gaReferrer.content) cookieValueDict.utmcct = gaReferrer.content;
        if (gaReferrer.term) cookieValueDict.utmctr = gaReferrer.term;
    }

    const cookieValue = Object.entries(cookieValueDict)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');

    if (cookieValue) {
        CookieUtils.setCookie(cookieName, `${nowTs}.${cookieValue}`, 10); // 180);
    }
}

export default writeUtmzCookie;
